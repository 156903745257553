import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import FakeStackOverflow from './components/fakestackoverflow.js';
import { disableReactDevTools } from '@fvilers/disable-react-devtools';

if (process.env.NODE_ENV === 'production') {
  disableReactDevTools();
}

ReactDOM.render(
  <FakeStackOverflow />,
  document.getElementById('root')
);
